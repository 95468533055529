import { validators } from '@/validation/index'

export default {
  acceptPensionAccount: {},
  dependant: {},
  dependantNumber: {},
  deskPhoneNumber: {},
  educationType: {},
  email: {
    required: validators.required
  },
  employmentLength: {},
  employmentType: {},
  firstName: {
    required: validators.required
  },
  idCode: {
    required: validators.required
  },
  idDocId: {},
  idDocNumber: {},
  lastName: {
    required: validators.required
  },
  maritalStatusId: {},
  monthlyIncome: {},
  monthlyObligations: {},
  netSalary: {},
  occupation: {},
  phoneNumber: {
    required: validators.required
  },
  residenceType: {},
  sourceOfIncome: {},
  customerId: {},
  sectorId: {
    required: validators.required
  },
  segmentId: {
    required: validators.required
  },
  countryId: {
    required: validators.required
  },
  residenceTypeId: {},
  residenceCountryId: {},
  countyId: {
    required: validators.required
  },
  city: {
    required: validators.required
  },
  fullAddress: {
    required: validators.required
  },
  postalCode: {
    required: validators.required
  },
  accountExternalId: {}
}
