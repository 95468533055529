import { CApplicationCorporateCustomer } from '@/models/application/CApplicationCorporateCustomer'
import { Expose, Transform } from 'class-transformer'
import { findCustomerMainAccount, findCustomerMainContact } from '@/helpers'

export class CorporateCustomerAdapter extends CApplicationCorporateCustomer {
  @Expose()
  @Transform(({ obj }) => obj.customerName, { toClassOnly: true })
  companyName: string;

  @Expose()
  @Transform(({ obj }) => findCustomerMainContact(obj.phoneNumbers), { toClassOnly: true })
  officialPhone: string

  @Expose()
  @Transform(({ obj }) => findCustomerMainContact(obj.emails), { toClassOnly: true })
  officialEmail: string

  @Expose()
  @Transform(({ obj }) => obj.idCode, { toClassOnly: true })
  companyRegistrationNumber: string

  @Expose()
  @Transform(({ obj }) => obj.corporateCustomer?.emtakId)
  emtakId: number

  @Expose()
  @Transform(({ obj }) => obj.corporateCustomer?.naceId)
  naceId: number

  @Expose()
  @Transform(({ obj }) => obj.corporateCustomer?.registeredCapital)
  registeredCapital: number

  @Expose()
  @Transform(({ obj }) => obj.id, { toClassOnly: true })
  customerId: number

  @Expose()
  @Transform(({ obj }) => findCustomerMainAccount(obj.externalBankAccounts), { toClassOnly: true })
  accountExternalId: string
}
