<template lang='pug'>
  .card-body
    .row
      .col-lg-6
        fi-form-field(:label = '$t("existingCustomer")')
          fi-customer-select(
            v-model      = 'selectedCustomerModel'
            :placeholder = '$t("existingCustomerPlaceholder")'
            :sector-id   = 'customerSectorId'
            :segment-id  = 'customerSegmentId'
          )
      .col-lg-6(v-if = 'removable')
        .fa-pull-right
          button.btn.btn-link.text-danger(@click.prevent = '$emit("remove")')
            i.far.fa-trash-alt.fa-lg
    .row
      .col-lg-6
        fi-form-field(:label = '$t("sector")')
          fi-select(
            v-model.number = 'customerData.sectorId.$model'
            :options       = 'sectors'
            :disabled      = '!!customerData[indexField].$model'
            required
            sm
          )
        fi-form-field(:label = '$t("segment")')
          fi-select(
            v-model.number = 'customerData.segmentId.$model'
            :options       = 'segments'
            :disabled      = '!!customerData[indexField].$model'
            required
            sm
          )
        fi-form-field(
          :label = '$t("firstName")'
          :state = '!customerData.firstName.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.firstName.required') {{ $t('common:required') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.firstName.$model'
            :disabled    = '!!customerData[indexField].$model'
            :class       = '{ "is-invalid": customerData.firstName.$error }'
          )

        fi-form-field(
          :label = '$t("lastName")'
          :state = '!customerData.lastName.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.lastName.required') {{ $t('common:required') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.lastName.$model'
            :disabled    = '!!customerData[indexField].$model'
            :class       = '{ "is-invalid": customerData.lastName.$error }'
          )

        fi-form-field(
          :label = '$t("idCode")'
          :state = '!customerData.idCode.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.idCode.required') {{ $t('common:required') }}

          input.form-control.form-control-sm.animated.fadeIn(
            type           = 'text'
            v-model = 'customerData.idCode.$model'
            :disabled      = '!!customerData[indexField].$model'
            :class         = '{ "is-invalid": customerData.idCode.$error }'
          )

        fi-form-field(
          :label = '$t("documentType")'
          :state = '!customerData.idDocId.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.idDocId.required') {{ $t('common:required') }}

          fi-select(
            v-model.number = 'customerData.idDocId.$model'
            :options       = 'optionsFromClassifier("idDocs")'
            :class         = '{ "is-invalid": customerData.idDocId.$error }'
            :placeholder   = '$t("idDocPlaceholder")'
            required
            sm
          )

        fi-form-field(
          :label = '$t("documentNumber")'
          :state = '!customerData.idDocNumber.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.idDocNumber.required') {{ $t('common:required') }}
          input.form-control.form-control-sm(
            v-model.number = 'customerData.idDocNumber.$model'
            type           = 'text'
            :class         = '{ "is-invalid": customerData.idDocNumber.$error }'
          )

          .alert.alert-danger(v-if = 'isIdDocExpire === true') {{ $t('expireDate', { idDocExpire: $t('common:formatDate', { value: idDocExpire }) }) }}
          .alert.alert-success(v-else-if = 'isIdDocExpire === false') {{ $t('expireDate', { idDocExpire: $t('common:formatDate', { value: idDocExpire }) }) }}

        fi-form-field(
          :label = '$t("phone")'
          :state = '!customerData.phoneNumber.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.phoneNumber.required') {{ $t('common:required') }}
          fi-phone-input.form-control-sm.form-control.animated.fadeIn(
            v-model.trim = 'customerData.phoneNumber.$model'
            :countries   = 'countries'
            :class       = '{ "is-invalid": customerData.phoneNumber.$error }'
          )

        fi-form-field(
          :label = '$t("deskPhone")'
          :state = '!customerData.deskPhoneNumber.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.deskPhoneNumber.required') {{ $t('common:required') }}
          fi-phone-input.form-control-sm.form-control.animated.fadeIn(
            v-model.trim = 'customerData.deskPhoneNumber.$model'
            :countries   = 'countries'
            :class       = '{ "is-invalid": customerData.deskPhoneNumber.$error }'
          )

        fi-form-field(
          :label = '$t("email")'
          :state = '!customerData.email.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.email.required') {{ $t('common:required') }}
            .error-message(v-if = '!customerData.email.email') {{ $t('common:emailError') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.email.$model'
            :class       = '{ "is-invalid": customerData.email.$error }'
          )

      .col-lg-6
        fi-form-field(
          :label = '$t("country")'
          :state = '!customerData.countryId.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.countryId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'customerData.countryId.$model'
            :options       = 'optionsFromClassifier("countries")'
            :class         = '{ "is-invalid": customerData.countryId.$error }'
            :placeholder   = '$t("countryPlaceholder")'
            required
            sm
          )

        fi-form-field(
          :label = '$t("county")'
          :state = '!customerData.countyId.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.countyId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'customerData.countyId.$model'
            :options       = 'counties'
            :class         = '{ "is-invalid": customerData.countyId.$error }'
            :placeholder   = '$t("countyPlaceholder")'
            required
            sm
          )

        fi-form-field(
          :label = '$t("city")'
          :state = '!customerData.city.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.city.required') {{ $t('common:required') }}

          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.city.$model'
            :class       = '{ "is-invalid": customerData.city.$error }'
          )

        fi-form-field(
          :label = '$t("address")'
          :state = '!customerData.fullAddress.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.fullAddress.required') {{ $t('common:required') }}

          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.fullAddress.$model'
            :class       = '{ "is-invalid": customerData.fullAddress.$error }'
          )
        fi-form-field(
          :label = '$t("postalCode")'
          :state = '!customerData.postalCode.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.postalCode.required') {{ $t('common:required') }}

          input.form-control.form-control-sm(
            v-model.trim = 'customerData.postalCode.$model'
            :class       = '{ "is-invalid": customerData.postalCode.$error }'
          )

        fi-form-field(:label = '$t("accountExternalId")')
          fi-select(
            v-model.trim = 'customerData.accountExternalId.$model'
            :options     = 'bankAccountOptions'
            sm
            required
          )
          input.form-control.form-control-sm(v-model = 'customerData.accountExternalId.$model')

        fi-form-field(
          :label = '$t("monthlyIncome")'
          :state = '!customerData.monthlyIncome.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.monthlyIncome.required') {{ $t('common:required') }}

          fi-money-input.form-control.form-control-sm(
            v-model.number = 'customerData.monthlyIncome.$model'
            type           = 'text'
            :class         = '{ "is-invalid": customerData.monthlyIncome.$error }'
          )

        fi-form-field(
          :label = '$t("monthlyObligations")'
          :state = '!customerData.monthlyObligations.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.monthlyObligations.required') {{ $t('common:required') }}

          fi-money-input.form-control.form-control-sm(
            v-model.number = 'customerData.monthlyObligations.$model'
            type           = 'text'
            :class         = '{ "is-invalid": customerData.monthlyObligations.$error }'
          )

        fi-form-field(
          :label = '$t("employmentLength")'
          :state = '!customerData.employmentLength.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.employmentLength.required') {{ $t('common:required') }}
          input.form-control.form-control-sm(
            v-model.number = 'customerData.employmentLength.$model'
            type           = 'text'
            :class         = '{ "is-invalid": customerData.employmentLength.$error }'
          )
        fi-form-field(
          :label = '$t("maritalStatus")'
          :state = '!customerData.maritalStatusId.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.maritalStatusId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'customerData.maritalStatusId.$model'
            :options       = 'optionsFromClassifier("maritalStatus")'
            :class         = '{ "is-invalid": customerData.maritalStatusId.$error }'
            sm
          )

        fi-form-field(:label = '$t("dependantNumber")')
          input.form-control.form-control-sm(
            v-model.number = 'customerData.dependantNumber.$model'
            type           = 'text'
          )
        fi-form-field(:label = '$t("residence")')
          .form-row
            .col
              fi-select.animated.fadeIn(
                v-model.number = 'customerData.$model.residenceCountryId'
                :options       = 'optionsFromClassifier("countries")'
                required
                sm
              )
            .col
              fi-select.animated.fadeIn(
                v-model.number = 'customerData.$model.residenceTypeId'
                :options       = 'optionsFromClassifier("residenceType")'
                sm
              )
</template>

<script>
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiSelect from '@/components/FiSelect'
import pick from 'lodash/pick'
import { mapGetters } from 'vuex'
import { codeWithHuman } from '@/mixins'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import { findCustomerMainContact } from '@/helpers'
import FiMoneyInput from '@/components/FiMoneyInput'
import { plainToClass } from 'class-transformer'
import { CApplicationPrivateCustomer } from '@/models/application/CApplicationPrivateCustomer.ts'

export default {
  name: 'application-customer-form-fields',

  components: { FiMoneyInput, FiCustomerSelect, FiPhoneInput, FiSelect, FiFormField, FiMultiselect },

  mixins: [codeWithHuman],

  props: {
    customerData: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    segments: {
      type: Array,
      required: true
    },
    sectors: {
      type: Array,
      required: true
    },
    removable: {
      type: Boolean,
      default: false
    },
    indexField: {
      type: String,
      default: 'customerId'
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedCustomer: null
  }),

  computed: {
    ...mapGetters('applications', ['customerDataForApplication']),
    ...mapGetters('classifiers', [
      'classifierByName',
      'classifierById',
      'optionsFromClassifier',
      'optionsFromCreditIssuers',
      'optionsFromCountiesByCountry',
      'classifierList',
      'productTypeById'
    ]),
    customerSectorId () {
      return this.sectors.map(({ value }) => value).toString()
    },
    customerSegmentId () {
      return this.segments.map(({ value }) => value).toString()
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.linkSystemCustomer(selected)
      }
    },
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.customerData.countryId.$model)
    },
    applicationCustomerData () {
      return this.customerDataForApplication(this.selectedCustomer)
    },
    bankAccountOptions () {
      return (this.applicationCustomerData?.externalBankAccounts || [])
        .map(({ accountExternalNumber }) => ({ value: accountExternalNumber, text: accountExternalNumber }))
    },
    isIdDocExpire () {
      if (!this.idDocExpire) return ''

      return this.$moment(this.idDocExpire).isBefore()
    },
    maritalStatusModel: {
      get () {
        const { classifier, code } = this.customerData.$model.maritalStatusId ?? {}
        return this.classifierByName(classifier, code)?.id
      },
      set (id) {
        this.customerData.maritalStatusId.$model = {
          ...this.classifierById('maritalStatus', id),
          classifier: 'maritalStatus'
        }
      }
    }
  },

  methods: {
    async linkSystemCustomer (customer) {
      this.selectedCustomer = customer
      this.customerData.$model = plainToClass(CApplicationPrivateCustomer, {
        ...this.customerData.$model,
        ...pick(this.applicationCustomerData, Object.keys(this.customerData.$model)),
        email: findCustomerMainContact(this.selectedCustomer.emails),
        phoneNumber: findCustomerMainContact(this.selectedCustomer.phoneNumbers),
        deskPhoneNumber: findCustomerMainContact(this.selectedCustomer.deskPhoneNumbers),
        fullAddress: this.selectedCustomer.addresses[0].fullAddress,
        residenceCountryId: this.selectedCustomer.residenceCountryId,
        residenceTypeId: this.selectedCustomer?.residenceType?.id === 'PERMANENT' ? 1 : this.selectedCustomer?.residenceType?.id === 'TEMPORARY' ? 2 : undefined,
        [this.indexField]: customer.id
      })
      this.selectedCustomer.residenceTypeId = this.selectedCustomer?.residenceType?.id === 'PERMANENT' ? 1 : this.selectedCustomer?.residenceType?.id === 'TEMPORARY' ? 2 : undefined

      this.$emit('input', this.customerData.$model)
    }
  }
}
</script>

<i18n>
en:
  customerTitle:               "Customer data"
  existingCustomer:            "Existing customer"
  existingCustomerPlaceholder: "Select customer"
  sector:                      "Sector"
  segment:                     "Segment"
  firstName:                   "First name"
  lastName:                    "Last name"
  idCode:                      "ID Code"
  phone:                       "Mobile phone"
  deskPhone:                   "Desk phone"
  email:                       "E-mail"
  country:                     "Country"
  countryPlaceholder:          "Select country"
  county:                      "County"
  countyPlaceholder:           "Select county"
  city:                        "City"
  address:                     "Address"
  postalCode:                  "Postal code"
  accountExternalId:           "Payout bank account"
  monthlyIncome:               "Monthly verified income"
  monthlyObligations:          "Monthly verified obligations"
  employmentLength:            "Current employment length in months"
  documentType:                "Document type"
  idDocPlaceholder:            "Select document type"
  documentNumber:              "Document number"
  expireDate:                  "Expire date: {{ idDocExpire }}"
  maritalStatus:               "Marital status"
  dependantNumber:             "Number of dependants"
  residence:                   "Residence"
et:
  customerTitle:               "Customer data"
  existingCustomer:            "Existing customer"
  existingCustomerPlaceholder: "Select customer"
  sector:                      "Sector"
  segment:                     "Segment"
  firstName:                   "First name"
  lastName:                    "Last name"
  idCode:                      "ID Code"
  phone:                       "Mobile phone"
  deskPhone:                   "Desk phone"
  email:                       "E-mail"
  country:                     "Country"
  countryPlaceholder:          "Select country"
  county:                      "County"
  countyPlaceholder:           "Select county"
  city:                        "City"
  address:                     "Address"
  postalCode:                  "Postal code"
  accountExternalId:           "Payout bank account"
  monthlyIncome:               "Monthly verified income"
  monthlyObligations:          "Monthly verified obligations"
  employmentLength:            "Current employment length in months"
  documentType:                "Document type"
  idDocPlaceholder:            "Select document type"
  documentNumber:              "Document number"
  expireDate:                  "Expire date: {{ idDocExpire }}"
  maritalStatus:               "Marital status"
  dependantNumber:             "Number of dependants"
  residence:                   "Residence"
ru:
  customerTitle:               "Customer data"
  existingCustomer:            "Existing customer"
  existingCustomerPlaceholder: "Select customer"
  sector:                      "Sector"
  segment:                     "Segment"
  firstName:                   "First name"
  lastName:                    "Last name"
  idCode:                      "ID Code"
  phone:                       "Mobile phone"
  deskPhone:                   "Desk phone"
  email:                       "E-mail"
  country:                     "Country"
  countryPlaceholder:          "Select country"
  county:                      "County"
  countyPlaceholder:           "Select county"
  city:                        "City"
  address:                     "Address"
  postalCode:                  "Postal code"
  accountExternalId:           "Payout bank account"
  monthlyIncome:               "Monthly verified income"
  monthlyObligations:          "Monthly verified obligations"
  employmentLength:            "Current employment length in months"
  documentType:                "Document type"
  idDocPlaceholder:            "Select document type"
  documentNumber:              "Document number"
  expireDate:                  "Expire date: {{ idDocExpire }}"
  maritalStatus:               "Marital status"
  dependantNumber:             "Number of dependants"
  residence:                   "Residence"
</i18n>
