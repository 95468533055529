import { validators } from '@/validation/index'

export default {
  customerId: {},
  sectorId: {
    required: validators.required
  },
  segmentId: {
    required: validators.required
  },
  companyName: {
    required: validators.required
  },
  companyRegistrationNumber: {
    required: validators.required
  },
  officialPhone: {
    required: validators.required
  },
  officialEmail: {
    required: validators.required
  },
  emtakId: {},
  naceId: {},
  countryId: {
    required: validators.required
  },
  countyId: {
    required: validators.required
  },
  city: {
    required: validators.required
  },
  fullAddress: {
    required: validators.required
  },
  postalCode: {
    required: validators.required
  },
  accountExternalId: {},
  registeredCapital: {},
  currentPaymentDefaults: {},
  currentPaymentDefaultAmount: {},
  currentTaxDebtAmount: {},
  historicalPaymentDefaults: {},
  historicalPaymentDefaultsAmount: {},
  historicalTaxDebtAmount: {},
  deferredTaxDebtAmount: {},
  unsubmittedTaxReports: {},
  taxesPaidLastQuarterAmount: {},
  annualReports: {
    $each: {}
  }
}
