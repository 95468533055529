<template lang='pug'>
  .card-body
    .row
      .col-lg-6
        fi-form-field(:label = '$t("existingCustomer")')
          fi-customer-select(
            v-model      = 'selectedCustomerModel'
            :placeholder = '$t("existingCustomerPlaceholder")'
            :sector-id   = 'customerSectorId'
            :segment-id  = 'customerSegmentId'
          )
      .col-lg-6(v-if = 'removable')
        .fa-pull-right
          button.btn.btn-link.text-danger(@click.prevent = '$emit("remove")')
            i.far.fa-trash-alt.fa-lg
    .row
      .col-lg-6
        fi-form-field(:label = '$t("sector")')
          fi-select(
            v-model.number = 'customerData.sectorId.$model'
            :options       = 'sectors'
            :disabled      = '!!customerData[indexField].$model'
            required
            sm
          )
        fi-form-field(:label = '$t("segment")')
          fi-select(
            v-model.number = 'customerData.segmentId.$model'
            :options       = 'segments'
            :disabled      = '!!customerData[indexField].$model'
            required
            sm
          )
        fi-form-field(
          :label = '$t("customerName")'
          :state = '!customerData.companyName.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.companyName.required') {{ $t('common:required') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.companyName.$model'
            :disabled    = '!!customerData[indexField].$model'
            :class       = '{ "is-invalid": customerData.companyName.$error }'
          )

        fi-form-field(
          :label = '$t("regCode")'
          :state = '!customerData.companyRegistrationNumber.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.companyRegistrationNumber.required') {{ $t('common:required') }}

          input.form-control.form-control-sm.animated.fadeIn(
            type           = 'text'
            v-model.number = 'customerData.companyRegistrationNumber.$model'
            :disabled      = '!!customerData[indexField].$model'
            :class         = '{ "is-invalid": customerData.companyRegistrationNumber.$error }'
          )

        fi-form-field(
          :label = '$t("phone")'
          :state = '!customerData.officialPhone.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.officialPhone.required') {{ $t('common:required') }}
          fi-phone-input.form-control-sm.form-control.animated.fadeIn(
            v-model.trim = 'customerData.officialPhone.$model'
            :countries   = 'countries'
            :class       = '{ "is-invalid": customerData.officialPhone.$error }'
          )

        fi-form-field(
          :label = '$t("email")'
          :state = '!customerData.officialEmail.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.officialEmail.required') {{ $t('common:required') }}
            .error-message(v-if = '!customerData.officialEmail.email') {{ $t('common:emailError') }}
          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.officialEmail.$model'
            :class       = '{ "is-invalid": customerData.officialEmail.$error }'
          )

        fi-form-field(:label = '$t("nace")')
          fi-multiselect(
            track-by         = 'id'
            label            = 'human'
            v-model          = 'selectedNACEModel'
            :options         = 'Object.values(classifierList("nace"))'
            :search-callback = 'loadClassifiersNACE'
            :loading         = '$vueLoading.isLoading("classifiers:nace:fetch")'
            :custom-label    = 'codeWithHuman'
          )
            template(#option = '{ option }')  {{ option.code }} - {{ option.human }}
        fi-form-field(:label = '$t("emtak")')
          fi-multiselect(
            track-by         = 'id'
            label            = 'human'
            v-model          = 'selectedEMTAKModel'
            :options         = 'Object.values(classifierList("emtak"))'
            :search-callback = 'loadClassifiersEMTAK'
            :loading         = '$vueLoading.isLoading("classifiers:emtak:fetch")'
            :custom-label    = 'codeWithHuman'
          )
            template(#option = '{ option }')  {{ option.code }} - {{ option.human }}
        fi-form-field(:label = '$t("registeredCapital")')
          input.form-control.form-control-sm(
            v-model.number = 'customerData.registeredCapital.$model'
            type         = 'number'
          )
      .col-lg-6
        fi-form-field(
          :label = '$t("country")'
          :state = '!customerData.countryId.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.countryId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'customerData.countryId.$model'
            :options       = 'optionsFromClassifier("countries")'
            :class         = '{ "is-invalid": customerData.countryId.$error }'
            :placeholder   = '$t("countryPlaceholder")'
            required
            sm
          )

        fi-form-field(
          :label = '$t("county")'
          :state = '!customerData.countyId.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.countyId.required') {{ $t('common:required') }}
          fi-select(
            v-model.number = 'customerData.countyId.$model'
            :options       = 'counties'
            :class         = '{ "is-invalid": customerData.countyId.$error }'
            :placeholder   = '$t("countyPlaceholder")'
            required
            sm
          )

        fi-form-field(
          :label = '$t("city")'
          :state = '!customerData.city.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.city.required') {{ $t('common:required') }}

          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.city.$model'
            :class       = '{ "is-invalid": customerData.city.$error }'
          )

        fi-form-field(
          :label = '$t("address")'
          :state = '!customerData.fullAddress.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.fullAddress.required') {{ $t('common:required') }}

          input.form-control.form-control-sm.animated.fadeIn(
            type         = 'text'
            v-model.trim = 'customerData.fullAddress.$model'
            :class       = '{ "is-invalid": customerData.fullAddress.$error }'
          )
        fi-form-field(
          :label = '$t("postalCode")'
          :state = '!customerData.postalCode.$error'
        )
          template(#error)
            .error-message(v-if = '!customerData.postalCode.required') {{ $t('common:required') }}

          input.form-control.form-control-sm(
            v-model.trim = 'customerData.postalCode.$model'
            :class       = '{ "is-invalid": customerData.postalCode.$error }'
          )

        fi-form-field(:label = '$t("accountExternalId")')
          fi-select(
            v-model.trim = 'customerData.accountExternalId.$model'
            :options     = 'bankAccountOptions'
            sm
            required
          )
          input.form-control.form-control-sm(v-model = 'customerData.accountExternalId.$model')
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiMultiselect from '@/components/FiMultiselect'
import FiPhoneInput from '@/components/FiPhoneInput'
import FiSelect from '@/components/FiSelect'
import { mapActions, mapGetters } from 'vuex'
import isEmpty from 'lodash/isEmpty'
import { codeWithHuman } from '@/mixins'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import { plainToClass } from 'class-transformer'
import { CorporateCustomerAdapter } from '@/models/application/CorporateCustomerAdapter.ts'
import { findCustomerMainAddress } from '@/helpers'

export default {
  name: 'application-corporate-customer-form',

  components: { FiCustomerSelect, FiPhoneInput, FiSelect, FiFormField, FiMultiselect },

  mixins: [codeWithHuman],

  props: {
    customerData: {
      type: Object,
      required: true
    },
    product: {
      type: Object,
      required: true
    },
    segments: {
      type: Array,
      required: true
    },
    sectors: {
      type: Array,
      required: true
    },
    removable: {
      type: Boolean,
      default: false
    },
    indexField: {
      type: String,
      default: 'customerId'
    },
    idDocExpire: {
      type: String,
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    selectedCustomer: null
  }),

  computed: {
    ...mapGetters('applications', ['customerDataForApplication']),
    ...mapGetters('classifiers', [
      'classifierByName',
      'classifierById',
      'optionsFromClassifier',
      'optionsFromCreditIssuers',
      'optionsFromCountiesByCountry',
      'classifierList',
      'productTypeById'
    ]),
    customerSectorId () {
      return this.sectors.map(({ value }) => value).toString()
    },
    customerSegmentId () {
      return this.segments.map(({ value }) => value).toString()
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.linkSystemCustomer(selected)
      }
    },
    selectedNACEModel: {
      get () {
        const model = this.classifierById('nace', this.customerData.naceId.$model)
        return isEmpty(model) ? null : model
      },
      set (value) {
        this.customerData.naceId.$model = value?.id || value
      }
    },
    selectedEMTAKModel: {
      get () {
        const model = this.classifierById('emtak', this.customerData.emtakId.$model)
        return isEmpty(model) ? null : model
      },
      set (value) {
        this.customerData.emtakId.$model = value?.id || value
      }
    },
    countries () {
      return Object.values(this.classifierList('countries')).map(({ name }) => name)
    },
    counties () {
      return this.optionsFromCountiesByCountry(this.customerData.countryId.$model)
    },
    applicationCustomerData () {
      return this.customerDataForApplication(this.selectedCustomer)
    },
    bankAccountOptions () {
      return (this.selectedCustomer?.externalBankAccounts || [])
        .map(({ accountExternalNumber }) => ({ value: accountExternalNumber, text: accountExternalNumber }))
    }
  },

  async created () {
    await this.loadClassifiersEMTAK()
    await this.loadClassifiersNACE()
  },

  methods: {
    ...mapActions('classifiers', ['loadClassifiersEMTAK', 'loadClassifiersNACE']),
    async linkSystemCustomer (customer) {
      this.selectedCustomer = customer
      const { city, fullAddress, postalCode, countryId, countyId } = findCustomerMainAddress(customer.addresses, {})

      this.customerData.$model = plainToClass(
        CorporateCustomerAdapter,
        {
          ...customer,
          city,
          fullAddress,
          postalCode,
          countryId,
          countyId
        }, { excludeExtraneousValues: true })

      this.$emit('input', customer)
    }
  }
}
</script>


<i18n>
en:
  customerTitle:               "Customer data"
  existingCustomer:            "Existing customer"
  existingCustomerPlaceholder: "Select customer"
  sector:                      "Sector"
  segment:                     "Segment"
  firstName:                   "First name"
  lastName:                    "Last name"
  customerName:                "Name"
  idCode:                      "ID Code"
  regCode:                     "Reg code"
  phone:                       "Mobile phone"
  deskPhone:                   "Desk phone"
  email:                       "E-mail"
  country:                     "Country"
  countryPlaceholder:          "Select country"
  county:                      "County"
  countyPlaceholder:           "Select county"
  city:                        "City"
  address:                     "Address"
  postalCode:                  "Postal code"
  accountExternalId:           "Payout bank account"
  nace:                        "NACE"
  emtak:                       "EMTAK"
  registeredCapital:           "Registered capital"
et:
  existingCustomer:            "Existing customer"
  existingCustomerPlaceholder: "Select customer"
  sector:                      "Sector"
  segment:                     "Segment"
  firstName:                   "First name"
  lastName:                    "Last name"
  customerName:                "Name"
  idCode:                      "ID Code"
  regCode:                     "Reg code"
  phone:                       "Mobile phone"
  deskPhone:                   "Desk phone"
  email:                       "E-mail"
  country:                     "Country"
  countryPlaceholder:          "Select country"
  county:                      "County"
  countyPlaceholder:           "Select county"
  city:                        "City"
  address:                     "Address"
  postalCode:                  "Postal code"
  accountExternalId:           "Payout bank account"
  nace:                        "NACE"
  emtak:                       "EMTAK"
  registeredCapital:           "Registered capital"
ru:
  existingCustomer:            "Existing customer"
  existingCustomerPlaceholder: "Select customer"
  sector:                      "Sector"
  segment:                     "Segment"
  firstName:                   "First name"
  lastName:                    "Last name"
  customerName:                "Name"
  idCode:                      "ID Code"
  regCode:                     "Reg code"
  phone:                       "Mobile phone"
  deskPhone:                   "Desk phone"
  email:                       "E-mail"
  country:                     "Country"
  countryPlaceholder:          "Select country"
  county:                      "County"
  countyPlaceholder:           "Select county"
  city:                        "City"
  address:                     "Address"
  postalCode:                  "Postal code"
  accountExternalId:           "Payout bank account"
  nace:                        "NACE"
  emtak:                       "EMTAK"
  registeredCapital:           "Registered capital"
</i18n>
